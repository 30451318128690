import React from 'react'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'

const Meta = ({ site, title }) => {
  const siteTitle = get(site, 'title')
  // title = title ? `${title} | ${siteTitle}` : siteTitle
  title = title ? `${title}` : siteTitle
  // site.path = site.path ? `${site.path}` : ''
  return (
    <Helmet
      title={title}
      meta={[
        {
          name: 'description',
          content: get(site, 'description'),
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        // {
        //   name: 'twitter:site',
        //   content: `@${get(site, 'twitter')}`,
        // },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:description',
          content: get(site, 'description'),
        },
        // {
        //   property: 'og:url',
        //   content: `${get(site, 'siteUrl')}${get(site, 'path')}`,
        // },
        {
          property: 'og:image',
          content: `https://ei-seiri.com/img/ogp.jpg`,
        },
      ]}
    />
  )
}
export default Meta
