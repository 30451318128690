import { Link } from 'gatsby'
import React from 'react'
import logo from '../../../content/images/logo.svg'
import Cta from 'components/cta'
import './style.scss'

const Footer = () => (
  <div className="footer">
    <div className="container">
      <hr className="border-primary" />
      <div className="row my-3 align-items-center">
        <div className="col-md-7">
          <p className="h4 text-primary">
            自宅で完結する遺品整理の一括見積もり
          </p>
          <p className="mb-0">
            自宅で完結する遺品整理の一括見積もりで、最安値の優良業者の見積もりを最大5社お届けいたします。
          </p>
        </div>
        <div className="col-md-5 mt-3 mt-md-0">
          <Cta />
        </div>
      </div>
      {/* <p className="mb-0">
        <Link to="/profile/">
          <strong>eイーセイリ</strong>について
        </Link>
      </p> */}
      <p className="mt-5 mb-3">
        <span className="border-right pr-2">
          <small>&copy; {new Date().getFullYear()} eイーセイリ</small>
        </span>
        <span className="border-right px-2">
          <small>
            <Link to="/about/">eイーセイリとは</Link>
          </small>
        </span>
        <span className="border-right px-2">
          <small>
            <Link to="/service/">サービスについて</Link>
          </small>
        </span>
        <span className="border-right px-2">
          <small>
            <Link to="/for-corporates/">法人・個人事業主の方へ</Link>
          </small>
        </span>
        <span className="px-2">
          <small>
            <Link to="/inquiry/">お問合せ</Link>
          </small>
        </span>
      </p>
    </div>
  </div>
)

export default Footer
