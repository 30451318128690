import React from 'react'
import emergence from 'emergence.js'
import { Link } from 'gatsby'

import Navi from 'components/navi'
import Footer from 'components/footer'
import { siteMetadata } from '../../../gatsby-config'
import Cta from 'components/cta'

import logo from '../../../content/images/logo.svg'

import 'modern-normalize/modern-normalize.css'
import 'prismjs/themes/prism.css'
import 'scss/gatstrap.scss'
class Layout extends React.Component {
  componentDidMount() {
    emergence.init()
  }

  componentDidUpdate() {
    emergence.init()
  }

  render() {
    const { children } = this.props
    return (
      <div>
        <header>
          <div className="u-bg"></div>
          <div className="container py-3">
            <div className="row no-gutters justify-content-between align-items-center">
              <div className="col-6 col-md-2">
                <Link to="/">
                  <img
                    className="img-fluid mb-0"
                    src={logo}
                    alt="eイーセイリのロゴ"
                  />
                </Link>
              </div>
              <div className="d-none d-md-block col-md-9 col-lg-5 px-md-3 px-lg-0">
                <p className="mb-0">日本の住環境を守り、育むために。</p>
                <p className="mb-0 font-weight-bold">
                  遺品整理・空き家対策の専門家をご紹介
                </p>
              </div>
              <div className="d-none d-lg-block col-lg-4">
                <Cta />
              </div>
            </div>
          </div>
        </header>
        <Navi title={siteMetadata.title} {...this.props} />
        {children}
        <Footer title={siteMetadata.title} author={siteMetadata.author} />
      </div>
    )
  }
}

export default Layout
