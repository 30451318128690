import React from 'react'

import './style.scss'

export default function Social() {
  return (
    <a href="/inquiry/" className="btn-cta w-100 py-2">
      <span className="h4 font-weight-bold c-dotted">自宅完結の見積もり</span>
      <br />
      <small>手数料無料／お見積もり無料／お断り歓迎</small>
    </a>
  )
}
