import React from 'react'
import { Link } from 'gatsby'

import './style.scss'

class Navi extends React.Component {
  render() {
    const { location, title } = this.props
    return (
      <nav className="navbar navbar-expand navbar-light flex-column flex-md-row bg-light sticky-top border-bottom">
        <div className="container">
          <div className="navbar-nav-scroll w-100">
            <ul className="navbar-nav bd-navbar-nav flex-row d-md-flex nav-justified">
              <li
                className={
                  location.pathname === '/' ? 'nav-item active' : 'nav-item'
                }
              >
                <Link to="/" className="nav-link">
                  ホーム
                </Link>
              </li>
              {/* <li
                className={
                  location.pathname === '/profile/'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <Link to="/profile/" className="nav-link">
                  Profile
                </Link>
              </li> */}
              <li
                className={
                  location.pathname === '/about/'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <Link to="/about/" className="nav-link">
                  eイーセイリとは
                </Link>
              </li>
              <li
                className={
                  location.pathname === '/service/'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <Link to="/service/" className="nav-link">
                  サービスについて
                </Link>
              </li>
              <li
                className={
                  location.pathname === '/for-corporates/'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <Link to="/for-corporates/" className="nav-link">
                  法人・個人事業主の方
                </Link>
              </li>
              <li
                className={
                  location.pathname === '/inquiry/'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <a href="/inquiry-feedback-requests/" className="nav-link">
                  お問い合わせ
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navi
